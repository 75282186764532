import React from "react"
export const DownloadPdfIcon = (props) => {
  return (

<div className="bg-gray-200 hover:bg-grey-400 rounded-md inline-block not-prose">
<a className="text-grey-darkest font-bold py-2 px-4 inline-flex items-center !no-underline not-prose max-w-none" href={props.path} target="_blank" rel="noopener noreferrer">
  <svg className="w-4 h-4 mr-2" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
  <div className="not-prose">
    <span className="ml-4 not-prose">{props.text}</span>
  </div>
</a>
</div>
  )
}

export default DownloadPdfIcon
