import React from "react"
export const ExtLinkButton = (props) => {
  return (

    <div className="bg-gray-200 hover:bg-grey-400 rounded-md inline-block not-prose">
      <a className="text-grey-darkest font-bold py-2 px-4 inline-flex items-center !no-underline not-prose max-w-none" href={props.path} target="_blank" rel="noopener noreferrer">
          <svg viewBox="0 0 24 24" class="w-4 h-4 mr-2" fill="none" stroke="currentColor" stroke-width="2px">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
        </svg>
        <div className="not-prose">
          <span className="ml-4 not-prose">{props.text}</span>
        </div>
      </a>
    </div>
  )
}

export default ExtLinkButton
